<template>
  <div v-if="editor">
    <MenuBar class="editor__header" :editor="editor" />
    <EditorContent class="editor__content d-flex overflow-auto" :editor="editor" :style="{ height: height, backgroundColor: '#F5F5F5' }" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import MenuBar from "./MenuBar.vue";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";

export default {
  name: "Editor",

  components: {
    EditorContent,
    MenuBar,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "500px",
    },
    placeholder: {
      type: String,
      default: "Write something ...",
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          blockquote: false,
          code: false,
          codeBlock: false,
          gapcursor: false,
          hardBreak: false,
          heading: false,
          horizontalRule: false,
          strike: false,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        Link,
      ],
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  background-color: #f5f5f5;

  & {
    flex-grow: 1;
    padding: 1rem;
  }

  &:focus-visible {
    outline: none;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }
}
</style>
