





































































































































import {
  ProfileClient,
  ProfileSummaryDto,
  UserContentClient,
  UserExternalLinkContentItemResult,
} from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import ProfileSummary from "@/components/contentItems/userContent/ProfileSummary.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import { mapGetters } from "vuex";

/**
 * This component is used to display the full content item display for external links
 */
export default Vue.extend({
  name: "UserExternalLinkContentItem",

  components: {
    BaseContentItem,
    DoceoImage,
    DoceoIcon,
    ContentItemDate,
    DraftUserExternalLinkContentItem: () =>
      import("../../drafts/externalLinks/DraftUserExternalLinkContentItem.vue"),

    ConfirmDialog,
    ProfileSummary,
  },

  props: {
    contentItem: Object as PropType<UserExternalLinkContentItemResult>,
    isPreview: {
      type: Boolean,
      default: false,
    },
    previewAttachments: {
      type: Array as PropType<File[]>,
      default: Array,
    },
    previewImages: {
      type: Array as PropType<File[]>,
      default: Array,
    },
    previewFeaturedImage: {
      type: File as PropType<File>,
      default: null,
    },
  },

  data: () => ({
    DOCEO_ICONS,
    isEditing: false,
    deleteDialogOpen: false,
    isDeleting: false,
    creatorSummary: null as ProfileSummaryDto | null,
  }),

  async mounted() {
    const profileClient = new ProfileClient();
    if (this.contentItem.ownerId) {
      this.creatorSummary = await profileClient.getProfileSummary(
        this.contentItem.ownerId
      );
    }
  },

  methods: {
    editButtonClicked() {
      this.isEditing = true;
    },
    deleteButtonClicked() {
      this.deleteDialogOpen = true;
    },
    async deleteConfirmed() {
      this.isDeleting = true;
      let userContentClient = new UserContentClient();
      await userContentClient.deleteUserContent(this.contentItem.contentItemId);
      this.isDeleting = false;
      this.deleteDialogOpen = false;
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapGetters(["userId", "hasSponsorRole", "hasAdminRole"]),
    /**
     * Return the list of authors seperated by commas.
     */
    authors(): string | null {
      let authors = this.contentItem.authors?.map(
        (x) => `${x.firstName} ${x.lastName}`
      );
      if (authors && authors.length > 0) {
        return authors.join(", ");
      }
      return null;
    },
    showEditButtons(): boolean {
      return !this.isPreview && (this.isCurrentUserAuthor || (this.isCurrentUserAdmin && this.isPromotedByOrVoiceOf));
    },
    isCurrentUserAdmin(): boolean {
      return this.hasAdminRole;
    },
    isCurrentUserAuthor(): boolean {
      return this.contentItem.ownerId == this.userId;
    },
    isPromotedBy(): boolean {
      return this.contentItem.promotedBy != undefined;
    },
    isVoiceOf(): boolean {
      return this.contentItem.voiceOf != undefined;
    },
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
  },
});
