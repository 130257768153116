





















































































































































































































import Vue, { PropType } from "vue";
import { mapState, mapActions } from "pinia";
import { Validation } from "vuelidate";
import {
  ContentAuthorResult,
  DraftUserExternalLinkContentItemResult,
  IUserContentAuthorDto,
  SponsorAccountClient,
  SponsorUserResult,
  UserContentAuthorDto
} from "@/api/DoceoApi";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "EditExternalLink",
  components: {
    DoceoIcon,
  },
  props: {
    item: {
      type: Object as PropType<DraftUserExternalLinkContentItemResult>,
      default: () => new DraftUserExternalLinkContentItemResult(),
    },
    validation: {
      type: Object as PropType<Validation>,
      default: Object,
    },
    showValidation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    updatedItem: new DraftUserExternalLinkContentItemResult(),
    newAttachments: [] as File[],
    newImages: [] as File[],
    newFeaturedImage: null as File | null,
    deletedAttachmentIds: [] as string[],
    deletedImageIds: [] as string[],
    deletedFeaturedImage: null as string | null,
    sponsors: null as SponsorUserResult[] | null,
    DOCEO_ICONS,
  }),
  created() {
    this.updatedItem = Object.assign({}, this.item);

    // If there is no author set, default to the logged in user being the author
    if ((this.updatedItem.authors == undefined || this.updatedItem.authors.length == 0) && !this.hasAdminRole) {
      if (this.updatedItem.authors == undefined) Vue.set(this.updatedItem, "authors", []);

      let contentAuthor: UserContentAuthorDto = new UserContentAuthorDto({
        firstName: this.firstName,
        lastName: this.lastName,
      });

      this.updatedItem.authors?.push(contentAuthor);
    }

    if (this.hasAdminRole) {
      this.fetchSponsors();
    }
  },
  methods: {
    change() {
      this.$emit("change", this.updatedItem);
    },

    addAuthor() {
      if (!this.updatedItem.authors) Vue.set(this.updatedItem, "authors", []); // https://vuejs.org/v2/guide/reactivity.html#For-Objects
      let emptyAuthor: IUserContentAuthorDto = {
        firstName: "",
        lastName: "",
      };
      this.updatedItem.authors?.push(new UserContentAuthorDto(emptyAuthor));
    },

    removeAuthor(index: number) {
      this.updatedItem.authors?.splice(index, 1);
    },

    setNewAttachments() {
      this.$emit("setNewAttachments", this.newAttachments);
    },
    setNewImages() {
      this.$emit("setNewImages", this.newImages);
    },
    setNewFeaturedImage() {
      this.$emit("setNewFeaturedImage", this.newFeaturedImage);
    },
    setDeletedAttachments() {
      this.$emit("setDeletedAttachments", this.deletedAttachmentIds);
    },
    setDeletedImages() {
      this.$emit("setDeletedImages", this.deletedImageIds);
    },
    setDeletedFeaturedImage() {
      this.$emit("setDeletedFeaturedImage", this.deletedFeaturedImage);
    },

    openAttachmentDialog() {
      let el = this.$refs.attachment_upload as HTMLElement;
      el.click();
    },
    openImageDialog() {
      let el = this.$refs.image_upload as HTMLElement;
      el.click();
    },
    openFeaturedImageDialog() {
      let el = this.$refs.featured_upload as HTMLElement;
      el.click();
    },

    removeOldAttachment(index: number) {
      if (!this.updatedItem.attachments) return;
      this.deletedAttachmentIds.push(this.updatedItem.attachments[index].id);
      this.updatedItem.attachments?.splice(index, 1);
      this.setDeletedAttachments();
    },
    removeOldImage(index: number) {
      if (!this.updatedItem.images) return;
      this.deletedImageIds.push(this.updatedItem.images[index].id);
      this.updatedItem.images?.splice(index, 1);
      this.setDeletedImages();
    },
    removeOldFeaturedImage() {
      if (!this.updatedItem.featuredImage) return;
      this.deletedFeaturedImage = this.updatedItem.featuredImage.id;
      this.updatedItem.featuredImage = undefined;
      this.setDeletedFeaturedImage();
    },

    removeNewAttachment(index: number) {
      this.newAttachments.splice(index, 1);
      this.setNewAttachments();
    },
    removeNewImage(index: number) {
      this.newImages.splice(index, 1);
      this.setNewImages();
    },
    removeNewFeaturedImage() {
      this.newFeaturedImage = null;
      this.setNewFeaturedImage();
    },

    addAttachment() {
      let el = this.$refs.attachment_upload as HTMLInputElement;
      let files = el.files;
      if (files != null && files.length > 0) {
        this.newAttachments.push(files[0]);
        this.setNewAttachments();
      }
    },
    addImage() {
      let el = this.$refs.image_upload as HTMLInputElement;
      let files = el.files;
      if (files != null && files.length > 0) {
        this.newImages.push(files[0]);
        this.setNewImages();
      }
    },
    addFeaturedImage() {
      let el = this.$refs.featured_upload as HTMLInputElement;
      let files = el.files;
      if (files != null && files.length > 0) {
        this.newFeaturedImage = files[0];
        this.setNewFeaturedImage();
      }
    },
    async fetchSponsors() {
      const client = new SponsorAccountClient();
      this.sponsors = await client.getSponsorUsers();
    },
    sponsorChanged(sponsor: SponsorUserResult) {
      Vue.set(this.updatedItem, "promotedBy", sponsor.firstName);

      if (
        this.hasAdminRole && this.updatedItem.creator != undefined
        && (this.updatedItem.authors == undefined || this.updatedItem.authors.length == 0)
      ) {
        if (this.updatedItem.authors == undefined) Vue.set(this.updatedItem, "authors", []);

        let contentAuthor: UserContentAuthorDto = new UserContentAuthorDto({
          firstName: this.updatedItem.creator.firstName,
          lastName: this.updatedItem.creator.lastName,
        });

        this.updatedItem.authors?.push(contentAuthor);
      }
    },
  },
  computed: {
    ...mapGetters(["name", "firstName", "lastName", "hasVoiceOfRole", "hasAdminRole"]),
    publishedDate(): String {
      if (this.updatedItem.publishedOn) {
        return dayjs(this.updatedItem.publishedOn).format("MM/DD/YYYY");
      }
      if (this.$route.query.publishDate) {
        let publishDate = this.$route.query.publishDate as string;
        return dayjs(publishDate).format("MM/DD/YYYY");
      }
      return dayjs().format("MM/DD/YYYY");
    },
    isDraftedOrPublished(): boolean {
      return this.updatedItem.contentItemId !== undefined;
    },
  },
  watch: {
    item() {
      this.updatedItem = Object.assign({}, this.item);
    },
    updatedItem() {
      this.change();
    },
  },
});
