














































































































































import { Attachment, ContentItemResult, ContentItemType, ContentLikeClient, UserExternalLinkContentItemResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import AddToFolioDialog from "@/components/contentItems/folios/AddToFolioDialog.vue";
import { mapGetters } from "vuex";
import { mapActions } from "pinia";
import { useErrorStore } from "@/store/errorStore";
import { useAttachmentStore } from "@/store/attachmentStore";
import DOCEO_ICONS from "@/constants/icons";
import { useConversationStore } from "@/store/conversationStore";
import { getContentItemResultColor } from "@/constants/contentColors";
import { getSaveResultIcon } from "@/constants/contentIcons";

/**
 * This is the base component for all content items
 */
export default Vue.extend({
  components: { DoceoIcon, DoceoImage, AddToFolioDialog },

  props: {
    contentItem: Object as PropType<ContentItemResult>,
    isPreview: {
      type: Boolean,
      default: false,
    },
    previewAttachments: Array as PropType<Array<File>>,
    previewImages: Array as PropType<Array<File>>,
    previewFeaturedImage: {} as PropType<File>,
  },

  created() {
    this.isLiked = this.contentItem.hasUserLikedContent;
    this.likes = this.contentItem.likes;
  },

  data: () => ({
    DOCEO_ICONS,
    isSaveHovered: false,
    likes: 0,
    isLiked: false,
    isLikeHovered: false,
    isLikeLoading: false,
    isAddToFolioDialogOpen: false,
  }),

  methods: {
    ...mapActions(useErrorStore, ["addError"]),
    ...mapActions(useAttachmentStore, ["getAttachmentAsync"]),
    /**
     * Navigate back one page. This doesn't go to a specific page as the content items
     * can be viewed from multiple pages. This ensures we always go back to the correct page
     */
    goBack() {
      this.$router.go(-1);
    },
    /**
     * Called when the save button has been pressed. Opens the Add To Folio dialog
     */
    savePressed() {
      if (this.isPreview) return;
      this.isAddToFolioDialogOpen = true;
    },
    /**
     * Called when the item has been added to a folio
     */
    addedToFolio() {
      this.isAddToFolioDialogOpen = false;
    },
    /*
     * Called when the like button has been pressed. Adds or removes the like state of the item
     */
    async likePressed() {
      if (this.isPreview) return;
      if (this.isLikeLoading) return;
      this.isLikeLoading = true;
      const contentLikeClient = new ContentLikeClient();
      if (this.isLiked) {
        this.isLiked = false;
        this.likes--;
        await contentLikeClient.removeLike(this.contentItem.contentItemId).catch(() => {
          this.addError("Couldn't remove like");
          this.isLiked = true;
          this.likes++;
        });
      } else {
        this.isLiked = true;
        this.likes++;
        await contentLikeClient.addLike(this.contentItem.contentItemId).catch(() => {
          this.addError("Couldn't add like");
          this.isLiked = false;
          this.likes--;
        });
      }
      this.isLikeLoading = false;
    },
    /**
     * Joins the public discussion for the content item and changes the route to the discussion.
     */
    async joinDiscussion() {
      if (this.isPreview) return;
      const conversationStore = useConversationStore();
      await conversationStore.joinPublicChatroom(this.contentItem.contentItemId);

      this.$router.push({
        name: "Discussions",
        query: { chatroomId: this.contentItem.contentItemId },
      });
    },

    async downloadAttachment(attachment: Attachment) {
      let attachmentData = await this.getAttachmentAsync(attachment.id);
      if (!attachmentData?.data) return;
      this.downloadBlob(attachmentData.data, attachment.fileName);
    },

    downloadPreviewAttachment(file: File) {
      this.downloadBlob(file, file.name);
    },

    downloadBlob(blob: Blob, filename: string | null | undefined) {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", filename ?? "");
      a.click();
      window.URL.revokeObjectURL(url); // prevent potential memory leaks
    },

    getPreviewUrl(file: File) {
      let url = window.URL.createObjectURL(file);
      window.setTimeout(() => window.URL.revokeObjectURL(url), 1000); // there is a better way to do this using the onload event of the image.
      return url;
    },
  },

  computed: {
    ...mapGetters(["hasSponsorRole", "hasDoctorRole"]),
    /**
     * Determine if the "below"" slot is being used.
     */
    hasBelowSlot(): boolean {
      return !!this.$slots["below"];
    },
    /**
     * Determine which save icon to display.
     * If the content item is being hovered or has been saved, it should be filled in, otherwise it should be an outline
     */
    saveIcon(): string {
      if (this.isSaved || this.isSaveHovered) {
        return getSaveResultIcon(this.contentItem);
      } else {
        return DOCEO_ICONS.SAVE_DEFAULT;
      }
    },
    /**
     * Determine which like icon to display.
     * If the content item is being hovered or has been liked, it should be filled in, otherwise it should be an outline
     */
    likeIcon(): string {
      if (this.isLiked || this.isLikeHovered) {
        return DOCEO_ICONS.LIKE_ORANGE;
      } else {
        return DOCEO_ICONS.LIKE_DEFAULT;
      }
    },
    /**
     * Determine the color that should be used for each type of content
     */
    defaultColor(): string {
      return getContentItemResultColor(this.contentItem);
    },
    /**
     * Determine if the content is either "Promoted By" or "Voice Of". This is used for styling differently for sponsored content
     */
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
    /**
     * Determine if the content is "Promoted By" content. This is used mainly for styling purposes.
     */
    isPromotedBy(): boolean {
      return this.contentItem.promotedBy != undefined;
    },
    /**
     * Determine if the content is "Voice Of" content. This is used mainly for styling purposes.
     */
    isVoiceOf(): boolean {
      return this.contentItem.voiceOf != undefined;
    },

    /**
     * Determine if the content is saved to any folios
     */
    isSaved(): boolean {
      return (this.contentItem.savedToFolioIds && this.contentItem.savedToFolioIds?.length > 0) ?? false;
    },
  },
});
