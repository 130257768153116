




































































import Vue from "vue";
import { ProfileClient, ProfileSummaryDto } from "@/api/DoceoApi";
import DoceoImage from "@/components/DoceoImage.vue";

export default Vue.extend({
  name: "ProfileSummary",

  components: { DoceoImage },

  props: {
    profileSummary: {
      type: ProfileSummaryDto,
      required: true,
    },
  },

  data: () => ({
    followLoading: false,
  }),

  methods: {
    async follow() {
      if (this.followLoading || !this.profileSummary.userId) return;
      const profileClient = new ProfileClient();
      this.followLoading = true;
      try {
        await profileClient.followUser(this.profileSummary.userId);
        this.profileSummary.isFollowed = true;
      } finally {
        this.followLoading = false;
      }
    },
    async unfollow() {
      if (this.followLoading || !this.profileSummary.userId) return;
      const profileClient = new ProfileClient();
      this.followLoading = true;
      try {
        await profileClient.unFollowUser(this.profileSummary.userId);
        this.profileSummary.isFollowed = false;
      } finally {
        this.followLoading = false;
      }
    },
  },
});
