import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=68212ae0&"
import script from "./MenuBar.vue?vue&type=script&lang=js&"
export * from "./MenuBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDivider,VTextField})
