






















































import Vue, { PropType } from "vue";
import { mapState, mapActions } from "pinia";

import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import dayjs from "dayjs";

export default Vue.extend({
  name: "CreateContentPublishConfirm",
  components: { DoceoIcon },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    publishing: {
      type: Boolean,
      default: false,
    },
    publishDate: {
      type: Date as PropType<Date>,
      default: null,
    },
  },
  created() {
    this.editedPublishDate = this.publishDate ? dayjs.utc(this.publishDate).format("YYYY-MM-DD") : dayjs.utc().format("YYYY-MM-DD");
  },
  data: () => ({
    editedPublishDate: "",
    datePickerOpen: false,
    DOCEO_ICONS,
  }),
  methods: {
    close() {
      if (this.publishing) return;
      this.$emit("close");
    },
    publish() {
      this.$emit("publish", this.editedPublishDate);
    },
  },
  computed: {},
  watch: {
    publishDate() {
      this.editedPublishDate = dayjs.utc(this.publishDate).format("YYYY-MM-DD");
    },
  },
});
