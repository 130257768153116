











import { ContentItemResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import DoceoIcon from "@/components/DoceoIcon.vue"
import DOCEO_ICONS from "@/constants/icons";

/**
 * This is the base component for all content items
 */
export default Vue.extend({
  components: { DoceoIcon },

  data: () => ({
    DOCEO_ICONS,
  }),

  props: {
    contentItem: Object as PropType<ContentItemResult>,
  },

  computed: {},
});
