









































import Vue from "vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "Form",
  components: { DoceoIcon },
  props: {
    persistent: Boolean,
    isOpen: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      default: "480px",
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    lazyValidation: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    DOCEO_ICONS,
    isValid: true,
  }),
  computed: {},
  methods: {
    /**
     * Emit an event to close the dialog
     */
    close() {
      this.$emit("close");
    },

    /**
     * Emit a submit event
     */
    submit() {
      if (this.isValid) {
        const form: any = this.$refs.form;
        this.$emit("submit", form);
      }
    }
  },
});
