















import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",

  props: {
    message: {
      type: String,
      required: true,
    },
    okayText: {
      type: String,
      default: "Okay",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    width: {
      type: String,
      default: "400px",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    okay() {
      this.$emit("okay");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
});
