<template>
  <div>
    <template v-for="(item, index) in items">
      <v-divider
        v-if="item.type === 'divider'"
        :key="`divider${index}`"
        class="d-inline mx-1"
        vertical
      />
      <MenuItem v-else :key="index" v-bind="item" />
    </template>
    <FormDialog
      :isOpen="urlDialog"
      title="Enter a link"
      buttonText="Set"
      @close="urlDialog = false"
      @submit="setLink"
    >
      <v-text-field v-model.lazy.trim="url" label="Link" clearable autofocus hide-details />
    </FormDialog>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
import FormDialog from '@/components/FormDialog.vue';

export default {
  components: {
    MenuItem,
    FormDialog
  },

  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      urlDialog: false,
      url: "",
      items: [
        {
          icon: 'format-bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'format-italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'link',
          title: 'Link',
          action: () => this.openLinkDialog(),
          isActive: () => this.editor.isActive('link'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'format-list-bulleted',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'format-list-numbered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'format-clear',
          title: 'Clear Format',
          action: () => this.editor.chain()
            .focus()
            .clearNodes()
            .unsetAllMarks()
            .run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'undo',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'redo',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    }
  },

  methods: {
    openLinkDialog() {
      this.url = this.editor.getAttributes('link').href;
      this.urlDialog = true;
    },
    setLink() {
      if (this.url) {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: this.url })
          .run();
      } else {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();
      }

      this.url = ""
      this.urlDialog = false;
    }
  },
};
</script>