














































































import Vue from "vue";
import { mapGetters } from "vuex";
import { mapState, mapActions } from "pinia";
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import { minLength, required, requiredIf } from "vuelidate/lib/validators";
import {
  AttachmentBelongsTo,
  AttachmentClient,
  ContentItemResult,
  ContentItemType,
  CreateDraftUserArticle,
  CreateDraftUserExternalLink,
  DraftUserArticleContentItemResult,
  DraftUserExternalLinkContentItemResult,
  ExternalLinkType,
  FileParameter,
  ICreateDraftUserArticle,
  ICreateDraftUserExternalLink,
  IUserArticleContentItemResult,
  IUserExternalLinkContentItemResult,
  UserArticleClient,
  UserArticleContentItemResult,
  UserDraftArticleClient,
  UserDraftExternalLinkClient,
  UserExternalLinkClient,
  UserExternalLinkContentItemResult,
} from "@/api/DoceoApi";

import EditArticle from "@/components/contentItems/drafts/articles/EditArticle.vue";
import UserArticleContentItem from "@/components/contentItems/userContent/articles/UserArticleContentItem.vue";
import EditExternalLink from "@/components/contentItems/drafts/externalLinks/EditExternalLink.vue";
import UserExternalLinkContentItem from "@/components/contentItems/userContent/externalLinks/UserExternalLinkContentItem.vue";
import CreateContentPublishConfirm from "@/components/contentItems/drafts/CreateContentPublishConfirm.vue";

import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "CreateContent",
  components: {
    EditArticle,
    EditExternalLink,
    UserArticleContentItem,
    UserExternalLinkContentItem,
    CreateContentPublishConfirm,
    DoceoIcon,
  },
  mixins: [validationMixin],
  data: () => ({
    contentItemId: null as string | null, // content item id (set only when draft is saved or published)
    contentItemType: null as ContentItemType | null, // content type (from url parameter)
    updatedItem: {} as ContentItemResult,
    newAttachments: [] as File[], // attachments to upload when saving
    newImages: [] as File[],
    newFeaturedImage: null as File | null,
    publishDate: null as Date | null, // publish date (taken from the query parameter or from dialog when publishing)
    isPreviewing: false,
    publishDialogOpen: false,
    savingDraft: false,
    publishing: false,
    showValidation: false,
    DOCEO_ICONS,
    externalLinkType: ExternalLinkType.Article as ExternalLinkType,
  }),
  validations(): any {
    switch (this.contentItemType) {
      case ContentItemType.DraftUserArticle:
        return {
          updatedItem: {
            title: {
              required,
            },
            authors: {
              required,
              minLength: minLength(1),
            },
            content: {
              required,
            },
            voiceOf: {
              required: requiredIf(() => this.hasVoiceOfRole),
            },
            creator: {
              userId: {
                required: requiredIf(() => this.hasAdminRole),
              },
            },
            promotedBy: {
              required: requiredIf(() => this.hasAdminRole),
            },
          },
        };
      case ContentItemType.DraftUserExternalLink:
        return {
          updatedItem: {
            title: {
              required,
            },
            summary: {
              required,
            },
            authors: {
              required,
              minLength: minLength(1),
            },
            externalLink: {
              required,
            },
            voiceOf: {
              required: requiredIf(() => this.hasVoiceOfRole),
            },
            creator: {
              userId: {
                required: requiredIf(() => this.hasAdminRole),
              },
            },
            promotedBy: {
              required: requiredIf(() => this.hasAdminRole),
            },
          },
        };
    }
    return {};
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      // set item type
      switch (this.$route.params.contentItemType) {
        case "article":
          this.contentItemType = ContentItemType.DraftUserArticle;
          break;
        case "externalLink":
          this.externalLinkType = ExternalLinkType[(this.$route.query.externalType as string) ?? "article"];
          this.contentItemType = ContentItemType.DraftUserExternalLink;
          break;
      }

      // set published date
      if (this.$route.query.publishDate) {
        let publishDate = this.$route.query.publishDate as string;
        this.publishDate = new Date(publishDate);
        this.updatedItem.publishedOn = this.publishDate;
      }
    },

    async uploadNewAttachmentsAndImages() {
      let attachmentClient = new AttachmentClient();

      // upload new attachments
      let newAttachmentIds = [] as string[];
      for (let attachment of this.newAttachments) {
        let fileParameter: FileParameter = {
          data: attachment,
          fileName: attachment.name,
        };
        let attachmentResult = await attachmentClient.createFromFile(AttachmentBelongsTo.ContentItem, fileParameter);
        newAttachmentIds.push(attachmentResult.id);
      }
      let allAttachmentIds = [...newAttachmentIds, ...(this.updatedItem.attachments?.map((a) => a.id) ?? [])];

      // upload new images
      let newImageIds = [] as string[];
      for (let image of this.newImages) {
        let fileParameter: FileParameter = {
          data: image,
          fileName: image.name,
        };
        let imageResult = await attachmentClient.createFromFile(AttachmentBelongsTo.ContentItem, fileParameter);
        newImageIds.push(imageResult.id);
      }
      let allImageIds = [...newImageIds, ...(this.updatedItem.images?.map((a) => a.id) ?? [])];

      // upload featured image
      let newFeaturedImageId = null as string | null;
      if (this.newFeaturedImage) {
        let fileParameter: FileParameter = {
          data: this.newFeaturedImage,
          fileName: this.newFeaturedImage.name,
        };
        let featuredImageResult = await attachmentClient.createFromFile(AttachmentBelongsTo.ContentItem, fileParameter);
        newFeaturedImageId = featuredImageResult.id;
      }

      return { allAttachmentIds, allImageIds, newFeaturedImageId };
    },

    async save(publish = false) {
      switch (this.contentItemType) {
        case ContentItemType.DraftUserArticle: {
          let contentItem = this.updatedItem as DraftUserArticleContentItemResult;

          let uploadResult = await this.uploadNewAttachmentsAndImages();

          // create draft
          let userDraftArticleClient = new UserDraftArticleClient();
          let draftArticle: ICreateDraftUserArticle = {
            title: contentItem.title,
            attachmentIds: uploadResult.allAttachmentIds,
            imageIds: uploadResult.allImageIds,
            featuredImageId: uploadResult.newFeaturedImageId ?? undefined,
            authors: contentItem.authors,
            content: contentItem.content,
            publishedOn: this.publishDate ?? undefined,
            voiceOf: contentItem.voiceOf,
            userId: contentItem.creator?.userId,
            promotedBy: contentItem.promotedBy,
          };
          this.contentItemId = await userDraftArticleClient.createDraftArticle(new CreateDraftUserArticle(draftArticle));

          // publish if requested
          if (publish) {
            let userArticleClient = new UserArticleClient();
            await userArticleClient.publishArticle(this.contentItemId);
          }
          break;
        }

        case ContentItemType.DraftUserExternalLink: {
          let contentItem = this.updatedItem as DraftUserExternalLinkContentItemResult;

          let uploadResult = await this.uploadNewAttachmentsAndImages();

          // create draft
          let userDraftExternalLinkClient = new UserDraftExternalLinkClient();
          let draftExternalLink: ICreateDraftUserExternalLink = {
            title: contentItem.title,
            summary: contentItem.summary,
            attachmentIds: uploadResult.allAttachmentIds,
            imageIds: uploadResult.allImageIds,
            featuredImageId: uploadResult.newFeaturedImageId ?? undefined,
            externalLinkType: this.externalLinkType,
            externalLink: contentItem.externalLink,
            authors: contentItem.authors,
            publishedOn: this.publishDate ?? undefined,
            voiceOf: contentItem.voiceOf,
            userId: contentItem.creator?.userId ?? "",
            promotedBy: contentItem.promotedBy,
          };
          this.contentItemId = await userDraftExternalLinkClient.createDraftArticle(new CreateDraftUserExternalLink(draftExternalLink));

          // publish if requested
          if (publish) {
            let userExternalLinkClient = new UserExternalLinkClient();
            await userExternalLinkClient.publishExternalLink(this.contentItemId);
          }
          break;
        }
      }
    },

    back() {
      if (this.isPreviewing) {
        this.isPreviewing = false;
      } else {
        this.$router.go(-1);
      }
    },
    async nextClicked() {
      if (!this.isPreviewing) {
        if (this.contentItemType == ContentItemType.UserArticle || this.contentItemType == ContentItemType.DraftUserArticle) {
          let article = this.updatedItem as UserArticleContentItemResult;
          let client = new UserDraftArticleClient();
          this.updatedItem.summary = await client.getSummaryFromContent(article.content ?? "");
        }
        this.isPreviewing = true;
      } else {
        if (!this.validate()) {
          this.isPreviewing = false;
        } else {
          this.publishDialogOpen = true;
        }
      }
    },

    async saveDraft() {
      if (!this.validate()) return;
      this.savingDraft = true;
      await this.save(false);
      this.savingDraft = false;

      this.$router.push({ name: "MyContent" });
    },

    async publishClicked(publishDate: string) {
      this.publishDate = publishDate != "" ? new Date(publishDate) : null;

      this.publishing = true;
      await this.save(true);
      this.publishing = false;

      this.publishDialogOpen = false;
      this.$router.push({ name: "MyContent" });
    },

    validate(): boolean {
      this.showValidation = true;
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    setItem(item: ContentItemResult) {
      this.updatedItem = item;
    },
    setAttachments(attachments: File[]) {
      this.newAttachments = attachments;
    },
    setImages(images: File[]) {
      this.newImages = images;
    },
    setFeaturedImage(featuredImage: File | null) {
      this.newFeaturedImage = featuredImage;
    },
  },
  computed: {
    ...mapGetters(["hasVoiceOfRole", "hasAdminRole"]),

    previewArticle() {
      // creates preview for user articles
      let article = this.updatedItem as UserArticleContentItemResult;
      let userArticle: IUserArticleContentItemResult = {
        ...article,
        publishedOn: this.publishDate ?? undefined,
        contentItemType: ContentItemType.UserArticle,
      };
      return Object.assign({}, userArticle);
    },

    previewExternalLink() {
      // creates preview for external links
      let externalLink = this.updatedItem as UserExternalLinkContentItemResult;
      let userExternalLink: IUserExternalLinkContentItemResult = {
        ...externalLink,
        contentItemType: ContentItemType.UserExternalLink,
      };
      return Object.assign({}, userExternalLink);
    },

    previewContent(): any {
      switch (this.contentItemType) {
        case ContentItemType.DraftUserArticle:
          return this.previewArticle;
        case ContentItemType.DraftUserExternalLink:
          return this.previewExternalLink;
      }
      return {};
    },

    editComponent() {
      switch (this.contentItemType) {
        case ContentItemType.DraftUserArticle:
          return EditArticle;
        case ContentItemType.DraftUserExternalLink:
          return EditExternalLink;
      }
      return {};
    },

    previewComponent() {
      switch (this.contentItemType) {
        case ContentItemType.DraftUserArticle:
          return UserArticleContentItem;
        case ContentItemType.DraftUserExternalLink:
          return UserExternalLinkContentItem;
      }
      return {};
    },
  },
  watch: {
    $route(to, from) {
      this.initialize();
    },
  },
});
