<template>
  <v-btn icon :class="{ 'is-active': isActive ? isActive() : null }" @click="action" :title="title" :ripple="false">
    <v-icon>mdi-{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    isActive: {
      type: Function,
      default: null,
    },
  },
};
</script>